import useMediaQuery from "../hooks/useMediaQuery";

export enum ScreenSizeEnum {
  MobileSmall, // max-width: 320px
  MobileMedium, // max-width: 375px
  MobileLarge, // max-width: 390px
  MobileLarge2, // max-width: 425px
  Tablet, // max-width: 768px
  Laptop // max-width: 1024px
}
