import React, {FunctionComponent as FC, useContext} from "react";
import styled from "styled-components";
import {WebsiteSliderItem} from "../../models/web/WebsiteSliderItem";
import {LanguageContext} from "../../provider/language";
import {Markdown} from "../markdown";

type StyledDescriptionProps = {showAtTop: boolean};

const StyledDescription = styled.div<StyledDescriptionProps>`
  position: absolute;
  background-color: ${(props) => props.theme.primary};
  bottom: 100px;
  max-width: 40%;
  @media (max-width: ${(props) => props.theme.breakpointsNo.sm - 1 + "px"}) {
    bottom: 120px;
    max-width: 50%;
  }
  right: 0px;
  padding: 32px 32px 16px 32px;
  ${({showAtTop}) =>
    showAtTop &&
    `
    top: 80px;
    bottom: auto !important;
  `}
`;

type HeroAreaDescriptionProps = {
  sliderItem: WebsiteSliderItem;
  showAtTop?: boolean;
};

export const HeroAreaDescription: FC<HeroAreaDescriptionProps> = ({
  sliderItem,
  showAtTop = false
}) => {
  const {locale} = useContext(LanguageContext);

  if (sliderItem && sliderItem.content && sliderItem.content.markdown) {
    return (
      <StyledDescription showAtTop={showAtTop}>
        <Markdown content={sliderItem.content.markdown[locale]} whiteLinks />
      </StyledDescription>
    );
  }

  return null;
};
