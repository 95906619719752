import {BackgroundImage, ImageSizedContainer} from "../heroarea/single-image";
import {FormattedMessage, useIntl} from "react-intl";
import Input, {InputFormatting} from "../input";
import "./virtual-ownership-header.css";
// @ts-ignore
import BackgroundImageFile from "../../../static/virtual_ownership_header_background_image.png";
import {ImagePiecesLayer} from "./image-pieces-layer";
import React, {useContext, useEffect, useRef, useState} from "react";
import {LanguageContext} from "../../provider/language";

// Size of small images from header.
const IMAGE_SIZE = {width: 75, height: 100};

/**
 * The header for the Virtual Ownership page.
 * @param inputValue - The value of the input field (NFT serial number).
 * @param handleInputChange - The function to handle input changes.
 * @param onSubmit - The function to handle form submission (Enter press).
 * @param onInfoClick - The function to handle info button click.
 * @param onSearchIconClick - The function to handle search icon click.
 * @constructor
 */
export const VirtualOwnershipHeader = ({
  inputValue,
  handleInputChange,
  onSubmit,
  onInfoClick,
  onSearchIconClick,
  inputErrorMessage
}) => {
  const intl = useIntl();

  const backgroundImagesGridContainerRef = useRef(null);
  const mainTextRef = useRef(null);

  const [backgroundImagesContainerInfo, setBackgroundImagesContainerInfo] =
    useState(null);
  // useState({width: 0, height: 0, top: 0, left: 0});

  const [mainTextInfo, setMainTextInfo] = useState(null);

  useEffect(() => {
    const calculateSizes = () => {
      const backgroundImagesContainerInfoValue = {
        width: backgroundImagesGridContainerRef.current.offsetWidth,
        height: backgroundImagesGridContainerRef.current.offsetHeight,
        top: backgroundImagesGridContainerRef.current?.offsetTop,
        left: backgroundImagesGridContainerRef.current?.offsetLeft
      };
      const mainTextInfoValue = {
        width: mainTextRef.current.offsetWidth + 35,
        height: mainTextRef.current.offsetHeight + 64,
        top: mainTextRef.current?.offsetTop + 20,
        left: mainTextRef.current?.offsetLeft - 35
      };
      setBackgroundImagesContainerInfo(backgroundImagesContainerInfoValue);
      setMainTextInfo(mainTextInfoValue);
    };

    calculateSizes();
    window.addEventListener("resize", calculateSizes);

    return () => {
      window.removeEventListener("resize", calculateSizes);
    };
  }, []);
  return (
    <>
      <BackgroundImage
        imageUrl={BackgroundImageFile}
        role="img"
        style={{minHeight: "500px"}}
      />
      <ImageSizedContainer style={{position: "absolute", minHeight: "500px"}}>
        <div
          className="container-fluid"
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            minHeight: "500px"
          }}
        ></div>
      </ImageSizedContainer>
      <ImageSizedContainer style={{textAlign: "center", minHeight: "500px"}}>
        <div className="container">
          <div className="top-part">
            <div className="container-fluid" style={{height: "100%"}}>
              <div
                className="row"
                style={{height: "100%", position: "relative"}}
              >
                <div
                  className="col-12 header-column"
                  style={{flexFlow: "column"}}
                >
                  <div ref={mainTextRef} style={{textTransform: "uppercase"}}>
                    <h1 className="header-title">
                      <FormattedMessage id="virtual-ownership.title" />
                    </h1>
                    <h3 className="header-subtitle">
                      <FormattedMessage id="virtual-ownership.subtitle" />
                    </h3>
                  </div>
                </div>
                <div
                  ref={backgroundImagesGridContainerRef}
                  className="col-12 background-images-grid-container"
                >
                  <ImagePiecesLayer
                    backgroundImagesContainerInfo={
                      backgroundImagesContainerInfo
                    }
                    mainTextInfo={mainTextInfo}
                    imagePieceSize={IMAGE_SIZE}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-part">
            <div className="div-input-styles">
              <Input
                className={"bottom-input-styles"}
                value={inputValue}
                onChange={handleInputChange}
                onSubmit={onSubmit}
                onInfoClick={onInfoClick}
                placeholder={intl.formatMessage({
                  id: "virtual-ownership.search.serial-number.placeholder"
                })}
                formatting={InputFormatting.gradient}
                style={{fontSize: "15px"}}
                onSearchIconClick={onSearchIconClick}
                errorMessage={inputErrorMessage}
              />
            </div>
          </div>
        </div>
      </ImageSizedContainer>
    </>
  );
};
