/**
 * Generates grid layout header
 * @param backgroundImagesContainerInfo - info about background images container
 * @param mainTextInfo - info about main text (location and dimensions)
 * @param imagePieceSize - size of image piece
 */
export function getGridLayout(
  backgroundImagesContainerInfo,
  mainTextInfo,
  imagePieceSize
) {
  const imagesLayoutArray = [];
  if (backgroundImagesContainerInfo) {
    const figure = {
      A: {x: mainTextInfo.left, y: mainTextInfo.top},
      B: {x: mainTextInfo.left + mainTextInfo.width, y: mainTextInfo.top},
      C: {
        x: mainTextInfo.left + mainTextInfo.width,
        y: mainTextInfo.top + mainTextInfo.height
      },
      D: {
        x: mainTextInfo.left,
        y: mainTextInfo.top + mainTextInfo.height
      }
    };
    for (
      let vertically = 0;
      vertically + imagePieceSize.height < backgroundImagesContainerInfo.height;
      vertically += imagePieceSize.height
    ) {
      for (
        let horizontally = 0;
        horizontally + imagePieceSize.width <
        backgroundImagesContainerInfo.width;
        horizontally += imagePieceSize.width
      ) {
        const currentFigure = {
          A: {x: horizontally, y: vertically},
          B: {x: horizontally + imagePieceSize.width, y: vertically},
          C: {
            x: horizontally + imagePieceSize.width,
            y: vertically + imagePieceSize.height
          },
          D: {
            x: horizontally,
            y: vertically + imagePieceSize.height
          },
          BC: {
            x: horizontally + imagePieceSize.width,
            y: vertically + imagePieceSize.height / 2
          },
          AD: {
            x: horizontally,
            y: vertically + imagePieceSize.height / 2
          }
        };

        imagesLayoutArray.push({
          left: horizontally,
          top: vertically,
          allowed:
            !contains(currentFigure.A, figure) &&
            !contains(currentFigure.B, figure) &&
            !contains(currentFigure.C, figure) &&
            !contains(currentFigure.D, figure) &&
            !contains(currentFigure.BC, figure) &&
            !contains(currentFigure.AD, figure)
        });
      }
    }
  }
  return imagesLayoutArray;
}
function contains({x, y}, figure) {
  return (
    x >= figure.A.x && x <= figure.B.x && y >= figure.A.y && y <= figure.D.y
  );
}
