import React, {FunctionComponent as FC, useContext} from "react";
import styled from "styled-components";
import {WebsiteSliderItem} from "../../models/web/WebsiteSliderItem";
import {LanguageContext} from "../../provider/language";
import Countdown from "./countdown";
import {CtaButton} from "./cta-button";
import {HeroAreaDescription} from "./heroareadescription";
import {TagCloud} from "./tag-cloud";

interface BackgroundImageProps {
  imageUrl: string;
}

export const BackgroundImage = styled.div<BackgroundImageProps>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin-top: 180px;
  height: calc(100vh - 180px);
  min-height: 400px;
  background: url(${(props) => props.imageUrl}) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

export const ImageSizedContainer = styled.div`
  height: calc(100vh - 180px);
  min-height: 400px;
  position: relative;
`;

export const Content = styled.div`
  position: absolute;
  bottom: 30px;
  text-align: center;
  transform: translateX(-50%);
  left: 50%;
`;

type BackgroundProps = {sliderItem: WebsiteSliderItem; showCountdown?: boolean};

export const SingleImage: FC<BackgroundProps> = ({
  sliderItem,
  showCountdown = false
}) => {
  const {locale} = useContext(LanguageContext);

  return (
    <>
      <BackgroundImage
        imageUrl={sliderItem.image.url}
        role="img"
        aria-label={sliderItem.image.altText[locale]}
      />
      <ImageSizedContainer>
        {sliderItem.content?.tagCloud ? (
          <div className="container">
            <TagCloud tagCloud={sliderItem.content.tagCloud} />
          </div>
        ) : null}
        <Content>
          {showCountdown ? (
            <Countdown visibleAfter={sliderItem.visibleAfter} />
          ) : null}
          {sliderItem.ctaButton && sliderItem.ctaButton.caption.de ? (
            <CtaButton
              url={sliderItem.ctaButton.url[locale]}
              caption={sliderItem.ctaButton.caption[locale]}
            />
          ) : null}
        </Content>
        {
          <HeroAreaDescription
            sliderItem={sliderItem}
            showAtTop={showCountdown}
          />
        }
      </ImageSizedContainer>
    </>
  );
};
