/*
  A function that checks if a string is all numbers
 */
export function isAllNumbers(str) {
  if (!str) return false;
  for (let i = 0; i < str.length; i++) {
    if (isNaN(parseInt(str[i]))) {
      return false;
    }
  }
  return true;
}
