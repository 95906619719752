import React, {FunctionComponent} from "react";
import styled from "styled-components";
import {ButtonBackground} from "../button";

// @ts-ignore
import SearchIcon from "../../../static/search-button.png";
// @ts-ignore
import InfoIcon from "../../../static/info_icon.svg";

import "./index.css";

export enum InputFormatting {
  roseTransparent = "roseTransparent",
  gradient = "gradient"
}

export enum InputType {
  number = "number",
  string = "string"
}

type InputProps = {
  className?: string;
  value: string;
  onChange: (e) => void;
  // Function triggered when the user presses Enter.
  onSubmit: (e?) => void;
  onInfoClick: (e?) => void;
  onSearchIconClick: (e?) => void;
  // Defines the styles used for input.
  placeholder?: string;
  formatting?: InputFormatting;
  type?: InputType;
  style?: React.CSSProperties;
  // Icon to be located on the left side of the input.
  icon?: any;
  errorMessage: string;
};

const StyledInput = styled.input<any>`
  ${({formatting}) =>
    formatting && formatting === InputFormatting.roseTransparent
      ? "color: #3e486b;" +
        "background: #ffffffcc 0% 0% no-repeat padding-box;" +
        "border: 4px solid #fa259c;" +
        "border-radius: 50px;" +
        "opacity: 1;"
      : formatting && formatting === ButtonBackground.gradient
      ? "background: transparent linear-gradient(90deg, #38B2E7 0%, #BE469A 100%) 0% 0% no-repeat padding-box;" +
        "border-radius: 50px;" +
        "opacity: 1;"
      : ""};
  ${({formatting}) =>
    formatting && formatting === InputFormatting.roseTransparent
      ? "color: #3e486b;" +
        "background: #ffffffcc 0% 0% no-repeat padding-box;" +
        "border: 4px solid #fa259c;" +
        "border-radius: 50px;" +
        "opacity: 1;"
      : ""};

  box-shadow: none;

  ::placeholder {
    color: rgba(255, 255, 255, 0.74);
  }

  &:focus {
    outline-width: 0;
  }

  &:hover {
    color: #ffffff;
  }
    /* Hide the increment and decrement buttons */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    display: none;
  }
`;

const ErrorMessage = styled.p`
  color: #af8991;
  position: absolute;
  max-width: 330px;
  @media (max-width: 768px) {
    max-width: 330px;
  }
  @media (max-width: 375px) {
    max-width: 270px;
  }
`;
/**
 * Input component.
 */
const Input: FunctionComponent<InputProps> = ({
  children,
  className,
  style,
  value,
  onChange,
  onSubmit,
  placeholder,
  type = InputType.string,
  formatting,
  onInfoClick,
  onSearchIconClick,
  icon,
  errorMessage
}) => {
  const onKeyDown = (e) => {
    if (onSubmit && e.key === "Enter") {
      onSubmit();
    }
  };

  if (formatting && InputFormatting.roseTransparent) {
    return (
      <>
        <div className="input-wrapper">
          <StyledInput
            placeholder={placeholder}
            className={`input-styles ${className}`}
            style={{
              ...style,
              ...(errorMessage ? {border: "solid 4px #622135"} : {})
            }}
            formatting={formatting}
            value={value}
            onChange={(e) => onChange(e)}
            onKeyDown={onKeyDown}
            type="number"
          />
          <img
            onClick={onInfoClick ? onInfoClick : () => {}}
            className={"info-icon"}
            src={InfoIcon}
            alt={""}
            style={{}}
          />
          <img
            className={"search-icon"}
            src={SearchIcon}
            alt={""}
            style={{}}
            onClick={onSearchIconClick ? onSearchIconClick : () => {}}
          />
        </div>
        <div
          style={{display: "flex", placeContent: "center", marginTop: "4px"}}
        >
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </div>
      </>
    );
  }
  return <input className={className}></input>;
};

export default Input;
