import moment from "moment";
import React, {FunctionComponent as FC, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import styled from "styled-components";

const Container = styled.div`
  flex: 1;
  padding: 6px 12px;
  margin: 12px 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.1)
  ); ;
`;

const Start = styled.h3`
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 2px;
  @media (max-width: ${(props) => props.theme.breakpointsNo.lg - 1 + "px"}) {
    font-size: 36px;
  }
`;

const Counter = styled.div`
  font-size: 150px;
  font-weight: lighter;
  & span {
    margin: 0px 10px;
  }
  @media (max-width: ${(props) => props.theme.breakpointsNo.lg - 1 + "px"}) {
    font-size: 96px;
    & span {
      margin: 0px 8px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpointsNo.sm - 1 + "px"}) {
    font-size: 64px;
    & span {
      margin: 0px 6px;
    }
  }
`;

type CountdownProbs = {visibleAfter: string};

const Countdown: FC<CountdownProbs> = ({visibleAfter}) => {
  const [now, setNow] = useState(moment());
  useEffect(() => {
    const interval = setInterval(() => setNow(moment()), 1000);
    return () => clearInterval(interval);
  }, []);
  const releaseDate = new Date(visibleAfter);
  const next = moment.duration(moment(releaseDate).diff(now));
  const padZero = (num: number): string => (num < 10 ? "0" : "") + num;
  const hours = padZero(next.hours());
  const days = Math.floor(next.asDays());
  const minutes = padZero(next.minutes());
  const seconds = padZero(next.seconds());
  if (next.asSeconds() < 0) {
    return null;
  }
  return (
    <>
      <Start>
        {days === 0 && <FormattedMessage id="main.countdown.no.days" />}
        {days === 1 && <FormattedMessage id="main.countdown.one.day" />}
        {days > 1 && (
          <FormattedMessage id="main.countdown.days" values={{days}} />
        )}
      </Start>

      <div className="align-self-center">
        <Container>
          <Counter>
            <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
          </Counter>
        </Container>
      </div>
    </>
  );
};
export default Countdown;
