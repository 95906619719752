import React, {FunctionComponent as FC, useContext} from "react";
import styled from "styled-components";
import {WebsiteSliderItemTagCloud} from "../../models/web/WebsiteSliderItem";
import {LanguageContext} from "../../provider/language";

const Keyword = styled.div<{$primary?: boolean; $seconds: number}>`
  font-size: 2rem;
  opacity: 0.85;
  ${({$primary}) => $primary && `opacity: 1;`}
  @media (max-width: ${(props) => props.theme.breakpointsNo.sm - 1 + "px"}) {
    font-size: 1.5rem;
  }
  @media (max-width: 400px) {
    display: none;
  }
  ${({$seconds}) =>
    `animation: fade-in-and-out 5s ease-in-out ${$seconds}s infinite;`}
`;

const Row = styled.div`
  padding: 48px 0;

  @media (max-width: ${(props) => props.theme.breakpointsNo.sm - 1 + "px"}) {
    padding: 24px 0;
  }
`;

type TagCloudProps = {tagCloud: WebsiteSliderItemTagCloud};

export const TagCloud: FC<TagCloudProps> = ({tagCloud}) => {
  const {locale} = useContext(LanguageContext);

  if (tagCloud.de.length != 4 || tagCloud.en.length != 4) return null;

  return (
    <>
      <Row className="row pt-5">
        <div className="col"></div>
        <div className="col">
          <Keyword $seconds={0}>{tagCloud[locale][0]}</Keyword>
        </div>
      </Row>
      <Row className="row">
        <div className="col-1"></div>
        <div className="col">
          <Keyword $primary $seconds={2}>
            {tagCloud[locale][1]}
          </Keyword>
        </div>
      </Row>
      <Row className="row">
        <div className="col-4 col-sm-5 col-md-9"></div>
        <div className="col">
          <Keyword $seconds={1}>{tagCloud[locale][2]}</Keyword>
        </div>
      </Row>
      <div className="row">
        <div className="col">
          <Keyword className="ml-2" $seconds={3}>
            {tagCloud[locale][3]}
          </Keyword>
        </div>
      </div>
    </>
  );
};
