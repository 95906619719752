import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
// @ts-ignore
import BrittoFriendshipImage from "../../static/britto_friendship_nft_image.png";
import "./grid-image.css";
import Button from "../components/button";
import {FormattedMessage} from "react-intl";
import {ScreenSizeEnum} from "../types/ScreenSizeEnum";

const GridDiv = styled.div<any>`
  border: rgba(190, 70, 154, 0.5) solid 1px;
`;

const SelectedGridDiv = styled.div<any>`
  background-color: rgba(190, 70, 154, 0.5);
  outline: 3px solid rgba(190, 70, 154, 1);
  transition-duration: 0.75s;
  background-size: cover;

  :hover {
    outline: 1px solid rgba(190, 70, 154, 1);
    transform: scale(6);
    background: ${({backgroundUrl}) =>
      backgroundUrl && "url(" + backgroundUrl + ")"};
    background-size: cover;
  }

  :focus {
    outline: 1px solid rgba(190, 70, 154, 1);
    transform: scale(5.5);
    background: ${({backgroundUrl}) =>
      backgroundUrl && "url(" + backgroundUrl + ")"};
    background-size: cover;
  }
`;

interface GridImageProps {
  x: number;
  y: number;
  backgroundUrl: string;
  parentWidth: number;
  screenSize: ScreenSizeEnum;
}

// Grid container that contains all the grid divs.
export const GridContainer = styled.div<any>`
  display: grid;
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  grid-template-rows: ${(props) => props.gridTemplateRows};
`;

/**
 * Renders a grid overlay on top of an image.
 * The grid is a 2D array of divs.
 * The selected div is used to render NFR piece image.
 * The image is rendered in the background of the selected div.
 * Implemented after mockup: https://xd.adobe.com/view/18a2b1d0-7762-4d38-9b0c-179ceb308a57-4df1/
 * @param x - x-axis coordinates of a selected piece of the grid.
 * @param y - y-axis coordinates of a selected piece of the grid.
 * @param backgroundUrl - url of the selected piece of the grid.
 * @param parentWidth - width of the parent container.
 * @param screenSize - device's size.
 */
const GridImage = ({
  x,
  y,
  backgroundUrl,
  parentWidth,
  screenSize
}: GridImageProps) => {
  const [width, height] = [50, 30];

  const [imageSizePixels, setImageSizePixels] = useState({
    width: 624,
    height: 500
  });
  const [selectedDivStyle, setSelectedDivStyle] = useState({});
  const [isAnimationRunning, setIsAnimationRunning] = useState(false);

  useEffect(() => {
    if (screenSize === ScreenSizeEnum.MobileSmall) {
      setImageSizePixels({width: 300, height: 240});
    } else if (screenSize === ScreenSizeEnum.MobileMedium) {
      setImageSizePixels({width: 330, height: 264});
    } else if (screenSize === ScreenSizeEnum.MobileLarge) {
      setImageSizePixels({width: 360, height: 288});
    } else if (screenSize === ScreenSizeEnum.MobileLarge2) {
      setImageSizePixels({width: 415, height: 332});
    } else if (screenSize === ScreenSizeEnum.Tablet) {
      setImageSizePixels({width: 450, height: 360});
    } else if (screenSize === ScreenSizeEnum.Laptop) {
      setImageSizePixels({width: 624, height: 500});
    }
  }, [screenSize]);

  const gridStyles = {};

  if (parentWidth < imageSizePixels.width) {
    gridStyles["marginLeft"] = -(imageSizePixels.width - parentWidth) / 2;
  }

  const divAfterTheImageSectionRef = useRef(null);

  const gridItemWidth = imageSizePixels.width / width;
  const gridItemHeight = imageSizePixels.height / height;
  // Grid width and height items will look like 12px 12px 12px
  const gridWidthItems =
      new Array(width).fill(gridItemWidth).join("px ") + "px",
    gridHeightItems = new Array(height).fill(gridItemHeight).join("px ") + "px";

  const imageSectionRef = useRef(null);
  const executeScroll = () =>
    setTimeout(
      () => divAfterTheImageSectionRef.current.scrollIntoView(false),
      10
    );

  const onLocalizeButtonClick = () => {
    // If the animation is already running, don't do anything.
    if (isAnimationRunning) {
      return;
    }
    setIsAnimationRunning(true);
    executeScroll();

    const addResizeWithTimeout = (
      i,
      max,
      timeoutExtra = 0,
      increase = true
    ) => {
      return setTimeout(
        () => {
          setSelectedDivStyle({
            transform: `scale(${i})`,
            outline: "1px solid rgba(190, 70, 154, 1)",
            background: `url(${backgroundUrl})`,
            backgroundSize: "cover"
          });
        },
        increase ? i * 1000 + timeoutExtra : (max - i) * 1000 + timeoutExtra
      );
    };

    // Start the animation after right away.
    const max = 8;
    let timeoutExtra = 0;
    // Zooming the image in.
    for (let i = 1; i < max; i++) {
      // adding multiple timeouts to make the animation smoother.
      addResizeWithTimeout(i, max);
      addResizeWithTimeout(i + 0.25, max);
      addResizeWithTimeout(i + 0.5, max);
      addResizeWithTimeout(i + 0.75, max);
    }

    // adding the extra time to the timeout to take into account time for zooming in.
    timeoutExtra += 1000 * max + 1000 * max;
    // Zooming the image out.
    for (let i = max; i > 0; i--) {
      // adding multiple timeouts to make the animation smoother.
      addResizeWithTimeout(i + 0.75, max, timeoutExtra, false);
      addResizeWithTimeout(i + 0.5, max, timeoutExtra, false);
      addResizeWithTimeout(i + 0.25, max, timeoutExtra, false);
      addResizeWithTimeout(i, max, timeoutExtra, false);
    }

    timeoutExtra += 1000 * max;

    setTimeout(() => {
      setSelectedDivStyle({});
      setIsAnimationRunning(false);
    }, timeoutExtra);
  };

  const arrayOfDivs = Array.from(Array(height).keys()).map((currentY) => {
    return Array.from(Array(width).keys()).map((currentX) => {
      return currentX === x && currentY === y ? (
        <SelectedGridDiv
          id="selected-grid-div"
          backgroundUrl={backgroundUrl}
          style={selectedDivStyle}
        />
      ) : (
        <GridDiv />
      );
    });
  });

  return (
    <>
      <div ref={imageSectionRef} style={{marginBottom: "15px"}}>
        <Button
          style={{dysplay: "flex", borderRadius: "50px"}}
          className="localize-button"
          gradient
          action={onLocalizeButtonClick}
        >
          <FormattedMessage id="virtual-ownership.image-grid.find-button" />
        </Button>
      </div>
      <div
        style={{
          ...gridStyles,
          width: imageSizePixels.width,
          height: imageSizePixels.height,
          marginTop: "57px",
          marginBottom: "100px"
        }}
      >
        <div className="img-magnifier-container">
          <img
            src={BrittoFriendshipImage}
            style={{
              position: "absolute",
              width: imageSizePixels.width,
              height: imageSizePixels.height
            }}
          />
        </div>
        <GridContainer
          gridTemplateColumns={gridWidthItems}
          gridTemplateRows={gridHeightItems}
          style={{position: "absolute"}}
        >
          {arrayOfDivs}
        </GridContainer>
      </div>
      <div ref={divAfterTheImageSectionRef}></div>
    </>
  );
};
export default GridImage;
