// @ts-ignore
import HeaderPiece1 from "../../../static/virtual_ownership_header_pieces_1.png";
// @ts-ignore
import HeaderPiece2 from "../../../static/virtual_ownership_header_pieces_2.png";
// @ts-ignore
import HeaderPiece3 from "../../../static/virtual_ownership_header_pieces_3.png";
// @ts-ignore
import HeaderPiece4 from "../../../static/virtual_ownership_header_pieces_4.png";
// @ts-ignore
import HeaderPiece5 from "../../../static/virtual_ownership_header_pieces_5.png";
// @ts-ignore
import HeaderPiece6 from "../../../static/virtual_ownership_header_pieces_6.png";
// @ts-ignore
import HeaderPiece7 from "../../../static/virtual_ownership_header_pieces_7.png";
// @ts-ignore
import HeaderPiece8 from "../../../static/virtual_ownership_header_pieces_8.png";
// @ts-ignore
import HeaderPiece9 from "../../../static/virtual_ownership_header_pieces_9.png";
// @ts-ignore
import HeaderPiece10 from "../../../static/virtual_ownership_header_pieces_10.png";
// @ts-ignore
import HeaderPiece11 from "../../../static/virtual_ownership_header_pieces_11.png";
// @ts-ignore
import HeaderPiece12 from "../../../static/virtual_ownership_header_pieces_12.png";
// @ts-ignore
import HeaderPiece13 from "../../../static/virtual_ownership_header_pieces_13.png";
// @ts-ignore
import HeaderPiece14 from "../../../static/virtual_ownership_header_pieces_14.png";
// @ts-ignore
import HeaderPiece15 from "../../../static/virtual_ownership_header_pieces_15.png";
// @ts-ignore
import HeaderPiece16 from "../../../static/virtual_ownership_header_pieces_16.png";
// @ts-ignore
import HeaderPiece17 from "../../../static/virtual_ownership_header_pieces_17.png";
// @ts-ignore
import HeaderPiece18 from "../../../static/virtual_ownership_header_pieces_18.png";
// @ts-ignore
import HeaderPiece19 from "../../../static/virtual_ownership_header_pieces_19.png";
// @ts-ignore
import HeaderPiece20 from "../../../static/virtual_ownership_header_pieces_20.png";

export const headerImages = [
  HeaderPiece1,
  HeaderPiece2,
  HeaderPiece3,
  HeaderPiece4,
  HeaderPiece5,
  HeaderPiece6,
  HeaderPiece7,
  HeaderPiece8,
  HeaderPiece9,
  HeaderPiece10,
  HeaderPiece11,
  HeaderPiece12,
  HeaderPiece13,
  HeaderPiece14,
  HeaderPiece15,
  HeaderPiece16,
  HeaderPiece17,
  HeaderPiece18,
  HeaderPiece19,
  HeaderPiece20
];
