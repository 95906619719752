import React, {
  FunctionComponent as FC,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import Divider from "../components/divider";
import styled from "styled-components";
import {RootContext} from "../root";
import {FirebaseContext} from "../provider/firebase";
import {Figure} from "../models/Figure";
import {AssetPublic} from "../models/AssetPublic";
import "./virtual-ownership.css";
// @ts-ignore
import GridImage from "../components/grid-image";
import {VirtualOwnershipHeader} from "../components/virtual-ownership/virtual-ownership-header";
import useMediaQuery from "../hooks/useMediaQuery";
import {ScreenSizeEnum} from "../types/ScreenSizeEnum";
import {Helmet} from "react-helmet";
import {StringParam, useQueryParam} from "use-query-params";
import {LanguageContext} from "../provider/language";
import {isAllNumbers} from "../helpers/string-helper";

type VirtualOwnershipProps = {
  location?: any;
};

const NftPiece = styled.img`
  border: 4px solid #b63d8f;
  max-height: 140px;
`;

const NFT_PIECES_COUNT = 1500;
/**
 * Virtual Ownership Page
 * Implemented after mockup: https://xd.adobe.com/view/18a2b1d0-7762-4d38-9b0c-179ceb308a57-4df1/
 */
const VirtualOwnership: FC<VirtualOwnershipProps> = ({location}) => {
  const {error, setError} = useContext(RootContext);
  const {firebase} = useContext(FirebaseContext);
  const [data, setData] = useState<{
    asset: AssetPublic;
    figure: Figure;
  }>(null);
  // If the data was already loaded default to `loading` == false
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const [selectedLocate] = useQueryParam("locale", StringParam);
  const {languages, setLocale} = useContext(LanguageContext);

  const [sequentialIdValue] = useQueryParam("sequentialId", StringParam);

  useEffect(() => {
    if (languages.find((i) => i.locale === selectedLocate)) {
      setLocale(selectedLocate);
    }
  }, [selectedLocate]);

  const onSubmit = async (inputValue: string) => {
    if (!inputValue) {
      setError(
        new Error(
          intl.formatMessage({
            id: "virtual-ownership.error.no-input-value-provided"
          })
        )
      );
      return;
    }
    if (!isAllNumbers(inputValue)) {
      setError(
        new Error(
          intl.formatMessage({
            id: "virtual-ownership.error.not-found"
          })
        )
      );
      return;
    }

    setData(null);
    const generatePublicAssetId = (
      sequentialId: string,
      collectionId: string
    ): string => {
      const parsedSequentialId = parseInt(sequentialId);
      return `${collectionId}_${parsedSequentialId}`;
    };

    try {
      const firestore = firebase.firestore();
      const collectionId = "n01nft";

      setLoading(data === null);
      setError(null);
      const assetId = generatePublicAssetId(
        inputValue.toString(),
        collectionId
      );

      const assetData = await firestore
        .collection("assetPublic")
        .doc(assetId)
        .get();
      if (!assetData.exists) {
        throw new Error(
          intl.formatMessage(
            {
              id: "virtual-ownership.error.not-found"
            },
            {serialNumber: inputValue}
          )
        );
      }

      setData({
        ...data,
        asset: assetData.data() as AssetPublic
      });
      executeScroll();
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const onInfoClick = () => {
    window.location.href = location.origin + `/nft/faq/?itemId=840`;
  };

  useEffect(() => {
    const sequentialId = parseInt(sequentialIdValue);
    if (firebase && sequentialId) {
      setInputValue(sequentialId.toString());
      onSubmit(sequentialId.toString());
    }
  }, [firebase, sequentialIdValue]);

  const [inputValue, setInputValue] = React.useState("");
  const [inputEvent, setInputEvent] = React.useState(null);

  function handleInputChange(event) {
    setInputValue(event.target.value);
    setInputEvent(event);
  }

  const imagePieceRowRef = useRef(null);
  const imagePieceSectionRef = useRef(null);
  const executeScroll = () => {
    if (inputEvent) {
      inputEvent.target.blur();
    }
    setTimeout(
      () =>
        imagePieceSectionRef.current.scrollIntoView({
          inline: "center"
        }),
      300
    );
  };
  const [
    isMobileSmall,
    isMobileMedium,
    isMobileLarge,
    isMobileLarge2,
    isTablet,
    isLaptop
  ] = [
    useMediaQuery("(max-width: 320px)"), // isMobileSmall
    useMediaQuery("(max-width: 375px)"), // isMobileMedium
    useMediaQuery("(max-width: 390px)"), // isMobileLarge
    useMediaQuery("(max-width: 425px)"), // isMobileLarge2
    useMediaQuery("(max-width: 768px)"), // isTablet
    useMediaQuery("(max-width: 1024px)") // isLaptop
  ];

  let screenSize = ScreenSizeEnum.Laptop;

  if (isMobileSmall) {
    screenSize = ScreenSizeEnum.MobileSmall;
  } else if (isMobileMedium) {
    screenSize = ScreenSizeEnum.MobileMedium;
  } else if (isMobileLarge) {
    screenSize = ScreenSizeEnum.MobileLarge;
  } else if (isMobileLarge2) {
    screenSize = ScreenSizeEnum.MobileLarge;
  } else if (isTablet) {
    screenSize = ScreenSizeEnum.Tablet;
  } else if (isLaptop) {
    screenSize = ScreenSizeEnum.Laptop;
  }

  useEffect(() => {
    if (isMobileSmall || isMobileMedium || isMobileLarge || isMobileLarge2) {
      document.body.style.transform = "scale(0.95)";
    }
  }, [isMobileSmall, isMobileMedium, isMobileLarge, isMobileLarge2]);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({id: "virtual-ownership.page-title"})}
        </title>
      </Helmet>
      <div></div>
      <div id="image-piece-section">
        <VirtualOwnershipHeader
          inputValue={inputValue}
          handleInputChange={handleInputChange}
          onSubmit={() => onSubmit(inputValue)}
          onInfoClick={() => onInfoClick()}
          onSearchIconClick={() => onSubmit(inputValue)}
          inputErrorMessage={error?.message}
        />
      </div>

      {data && data.asset && (
        <div ref={imagePieceSectionRef} className="container data-container">
          <div className="row centralized-row">
            <div className="col-md-8 col-lg-6">
              <div className="row" style={{marginTop: "20px"}}>
                <div className="col-12">
                  <h1 style={{fontWeight: "lighter"}}>
                    <FormattedMessage id="virtual-ownership.info.title" />
                  </h1>
                  <p className="items-count items-of-section">
                    {data.asset?.sequentialId}
                  </p>
                  <p className="items-of-section">
                    <FormattedMessage id="virtual-ownership.info.subtitle" />
                  </p>
                  <p className="items-of-section">{NFT_PIECES_COUNT}</p>
                </div>
              </div>
              <div
                className="row"
                style={{marginTop: "20px", color: "#9296A5"}}
              >
                <div className="col-12">
                  <p style={{marginBottom: "0"}}>
                    <FormattedMessage id="virtual-ownership.subheader" />
                  </p>
                  <Divider />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h5>
                    <FormattedMessage id="virtual-ownership.subheader.info" />
                  </h5>
                </div>
                <div className="col-12">
                  <FormattedMessage id="virtual-ownership.text" />
                </div>
              </div>
              <div className="row">
                <div className="col-12"></div>
              </div>

              <div className="row" style={{marginTop: "25px"}}>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-12">
                      <h5>
                        <FormattedMessage id="virtual-ownership.title.your-painting-part" />
                      </h5>
                    </div>
                  </div>
                  <NftPiece src={data.asset?.nft?.painting.image.url} />
                </div>
                <div className="col-md-6">
                  <div className="row painting-part-position-section">
                    <div className="col-12">
                      <h5>
                        <FormattedMessage id="virtual-ownership.title.painting-part-position" />
                      </h5>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-8">
                          <p>
                            <FormattedMessage id="virtual-ownership.title.painting-part-position.x" />
                          </p>
                        </div>
                        <div className="col-4 position-col">
                          {data.asset?.nft?.painting.image.xPosition}
                        </div>
                        <div className="col-8">
                          <p>
                            <FormattedMessage id="virtual-ownership.title.painting-part-position.y" />
                          </p>
                        </div>
                        <div className="col-4 position-col">
                          {data.asset?.nft?.painting.image.yPosition}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                ref={imagePieceRowRef}
                className="row"
                style={{
                  marginTop: "50px",
                  marginBottom: "20px",
                  placeContent: "center"
                }}
              >
                <GridImage
                  parentWidth={imagePieceRowRef?.current?.offsetWidth}
                  x={data.asset?.nft?.painting.image.xPosition}
                  y={data.asset?.nft?.painting.image.yPosition}
                  backgroundUrl={data.asset?.nft?.painting.image.url}
                  screenSize={screenSize}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VirtualOwnership;
