import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {headerImages} from "./header-images";
import {GridContainer} from "../grid-image";
import {getGridLayout} from "./virtual-ownership.helper";

const ImageDiv = styled.div<any>`
  background: ${({backgroundUrl}) =>
    backgroundUrl && "url(" + backgroundUrl + ")"};
  width: 100%;
  height: 100%;
`;
const INTERVAL = 1000;
const images = headerImages.sort(() => Math.random() - 0.5);
let DEFAULT_IMAGES_COUNT = 5;

const initDisplayedImages = (imagesCount: number) => {
  const displayedImages = [];
  for (let i = 0; i < imagesCount; i++) {
    displayedImages.push({
      imageIndex: i,
      tick: i
    });
  }
  return displayedImages;
};

const getNextImageIndex = (currentIndex: number): number => {
  if (currentIndex === images.length - 1) {
    return 0;
  }
  return currentIndex + 1;
};

enum ImagePieceState {
  STATIC = 0,
  APPEARING = 1,
  DISAPPEARING = 2
}

// generates item key based on its position.
const generateItemPieceKey = (left: number, top: number) => `${left}-${top}`;

const getNextState = (currentState: ImagePieceState): ImagePieceState => {
  switch (currentState) {
    case ImagePieceState.STATIC:
      return ImagePieceState.DISAPPEARING;
    case ImagePieceState.APPEARING:
      return ImagePieceState.DISAPPEARING;
    case ImagePieceState.DISAPPEARING:
      return ImagePieceState.APPEARING;
    default:
      return ImagePieceState.STATIC;
  }
};

export const ImagePiecesLayer = ({
  backgroundImagesContainerInfo,
  mainTextInfo,
  imagePieceSize
}) => {
  const [tick, setTick] = useState(0);
  const [imagePiecesState, setImagePiecesState] = useState<ImagePieceState>(
    ImagePieceState.STATIC
  );

  const [displayedImages, setDisplayedImages] = useState<
    {
      imageIndex: number;
      state: ImagePieceState;
      key: string;
      tick: number;
      location?: {top: number; left: number};
    }[]
  >([]);

  const [lastUsedImageIndex, setLastUsedImageIndex] = useState(0);
  const [imagesCount, setImagesCount] = useState(5);

  const [imagesLayout, setImagesLayout] = useState([]);
  const [imagesAllowedPlaces, setImagesAllowedPlaces] = useState([]);

  const [imagesInitialized, setImagesInitialized] = useState(false);

  useEffect(() => {
    setImagesInitialized(false);
    setLastUsedImageIndex(DEFAULT_IMAGES_COUNT - 1);
    const timer = setInterval(() => {
      setTick((i) => i + 1);
    }, INTERVAL);

    // init images here

    return () => {
      clearInterval(timer);
    };
  }, []);

  // On every tick we replace one image.
  useEffect(() => {
    if (displayedImages?.length && imagesLayout?.length) {
      if (!imagesInitialized) {
        // setting images positions.
      } else if (imagePiecesState === ImagePieceState.STATIC) {
        // Do nothing.
      } else if (imagePiecesState === ImagePieceState.DISAPPEARING) {
        /////// DISAPPEARING
        // find the item from displayedImages with the biggest tick.
        let maxTick = 0;
        let maxTickIndex = 0;
        let usedImageIndexes = [];
        displayedImages.forEach((item, index) => {
          usedImageIndexes.push(item.imageIndex);
          item.tick++;
          if (item.tick > maxTick) {
            maxTick = item.tick;
            maxTickIndex = index;
          }
        });

        const newDisplayedImages = JSON.parse(JSON.stringify(displayedImages));
        newDisplayedImages[maxTickIndex].state = ImagePieceState.DISAPPEARING;
        setDisplayedImages(newDisplayedImages);
      } else if (imagePiecesState === ImagePieceState.APPEARING) {
        /////// APPEARING
        // replace the disappeared image with a new one.
        const newImageIndex = getNextImageIndex(lastUsedImageIndex);

        const newDisplayedImages = JSON.parse(JSON.stringify(displayedImages));
        const itemToReplace = newDisplayedImages.findIndex(
          (item) => item.state === ImagePieceState.DISAPPEARING
        );
        newDisplayedImages[itemToReplace] = {};

        // find a new place for the image.
        const imagesAllowedPlacesCopy = JSON.parse(
          JSON.stringify(imagesAllowedPlaces)
        ).sort(() => Math.random() - 0.5);

        let newItemLocation;
        let flag = false;

        for (let i = 0; i < imagesAllowedPlacesCopy.length; i++) {
          const item = imagesAllowedPlacesCopy[i];
          // Checking if this position is occupied.
          const found = newDisplayedImages.find((i) => item.key === i.key);
          if (!found) {
            newItemLocation = item;
            flag = true;
            break;
          }
        }

        newDisplayedImages[itemToReplace] = {
          key: generateItemPieceKey(newItemLocation.left, newItemLocation.top),
          imageIndex: newImageIndex,
          state: ImagePieceState.APPEARING,
          tick: 0,
          location: {
            top: newItemLocation.top,
            left: newItemLocation.left
          }
        };
        setDisplayedImages(newDisplayedImages);
        setLastUsedImageIndex(newImageIndex);
      }
    }
    setImagePiecesState(getNextState(imagePiecesState));
  }, [tick]);

  useEffect(() => {
    if (backgroundImagesContainerInfo && mainTextInfo) {
      const imagesLayout = getGridLayout(
        backgroundImagesContainerInfo,
        mainTextInfo,
        imagePieceSize
      );

      const allowedPlaces = imagesLayout
        .filter((i) => i.allowed)
        .sort(() => Math.random() - 0.5)
        .map((i) => ({...i, key: generateItemPieceKey(i.left, i.top)}));

      const allowedImagesCount =
        DEFAULT_IMAGES_COUNT > allowedPlaces.length
          ? allowedPlaces.length - 1
          : DEFAULT_IMAGES_COUNT;

      const images = initDisplayedImages(allowedImagesCount);

      const newDisplayedImages = images.map((item, index) => {
        // TODO: Check on allowedPlaces length.
        const itemLocation = allowedPlaces[index];
        const itemKey = generateItemPieceKey(
          itemLocation.left,
          itemLocation.top
        );
        return {
          ...item,
          key: itemKey,
          location: {
            top: itemLocation.top,
            left: itemLocation.left
          },
          state: ImagePieceState.STATIC
        };
      });
      newDisplayedImages[newDisplayedImages.length - 1].state =
        ImagePieceState.DISAPPEARING;

      setImagesInitialized(true);
      setDisplayedImages(newDisplayedImages);

      setImagesLayout(imagesLayout);
      setImagesAllowedPlaces(allowedPlaces);
      setImagesCount(allowedImagesCount);
    }
  }, [backgroundImagesContainerInfo, mainTextInfo]);

  let gridImagesCount = {
    vertically: 0,
    horizontally: 0
  };

  let gridWidthItems = "0px",
    gridHeightItems = "0px";
  if (backgroundImagesContainerInfo && mainTextInfo) {
    gridImagesCount = {
      vertically: Math.trunc(
        backgroundImagesContainerInfo.height / imagePieceSize.height
      ),
      horizontally: Math.trunc(
        backgroundImagesContainerInfo.width / imagePieceSize.width
      )
    };

    gridWidthItems =
      new Array(gridImagesCount.horizontally)
        .fill(imagePieceSize.width)
        .join("px ") + "px";
    gridHeightItems =
      new Array(gridImagesCount.vertically)
        .fill(imagePieceSize.height)
        .join("px ") + "px";
  }
  const arrayOfDivs = imagesLayout.map((i) => {
    const key = generateItemPieceKey(i.left, i.top);
    const imageItem = displayedImages.find((i) => i.key === key);

    if (!imageItem) {
      return <div key={key} />;
    }

    return (
      <ImagePieceComponent
        key={key}
        keyValue={key}
        imageItem={imageItem}
        backgroundUrl={images[imageItem.imageIndex]}
        itemState={imageItem.state}
      />
    );
  });
  return (
    <>
      <GridContainer
        gridTemplateColumns={gridWidthItems}
        gridTemplateRows={gridHeightItems}
        style={{position: "absolute", justifyItems: "center"}}
      >
        {arrayOfDivs}
      </GridContainer>
    </>
  );
};

const ImagePieceComponent = ({
  keyValue,
  backgroundUrl,
  imageItem,
  itemState
}) => {
  const [itemStyle, setItemStyle] = useState({opacity: 0});

  useEffect(() => {
    if (itemState === ImagePieceState.APPEARING) {
      setItemStyle({
        opacity: 0
      });
      for (let i = 0; i <= 100; i += 5) {
        setTimeout(() => {
          setItemStyle({
            opacity: i / 100
          });
        }, i * 10);
      }
    } else if (itemState === ImagePieceState.DISAPPEARING) {
      for (let i = 0; i <= 100; i += 5) {
        setTimeout(() => {
          setItemStyle({
            opacity: 1 - i / 100
          });
        }, i * 10);
      }
    } else {
      setItemStyle({
        opacity: 1
      });
    }
  }, [itemState]);

  return (
    <ImageDiv style={itemStyle} key={keyValue} backgroundUrl={backgroundUrl} />
  );
};
